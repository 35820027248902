import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://fd2b50e7c7c6a9c3383a7a6b42ed19fa@o4506679064723456.ingest.us.sentry.io/4507873189036032",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});